// import './bootstrap';

// import jquery from 'jquery';
// window.jQuery = jquery;
// window.$ = jquery;

// import $ from 'jquery';
// import jQuery from 'jquery';
// export for others scripts to use
// window.$ = $;
// window.jQuery = jQuery;
// window.jQuery = jQuery;

import popper from 'popper.js';
window.Popper = popper.default;

import _ from 'lodash';
window._ = _;

import 'bootstrap'
import 'datatables.net'
import 'datatables.net-bs4';
import 'bootstrap4-toggle';
import 'bootstrap-modal-js';